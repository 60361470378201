@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --circle-size: clamp(1.5rem, 5vw, 2rem);
  --spacing: clamp(0.25rem, 2vw, 0.25rem);
  --circle-size1: clamp(1.5rem, 5vw, 1rem);
  --spacing1: clamp(1rem, 2vw, 0rem);
}
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@200;400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");

@font-face {
  font-family: "JannaLT";
  src: url("../fonts/JannaLT-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "JannaLT";
  src: url("../fonts/JannaLTBold.ttf") format("truetype");
  font-weight: 700;
}

body {
  font-family: "JannaLT", sans-serif;
}

input:focus,
textarea:focus {
  box-shadow: unset;
  border-color: #712fed;
  border-width: 2px;
  outline: none;
}

.custom-bg {
  background-image: url("/src/assets/images/custom.svg");
  background-color: #240a63;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  /* color: #fff; */
}

.lesson-stepper {
  display: flex;
}

.lesson-stepper-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.lesson-stepper-item span {
  width: 100px;
  height: 35px;
  font-size: 12px;
  font-weight: 700;
  padding-top: 5px;
  text-align: center;
}

.lesson-stepper-item:not(:last-child):after {
  content: "";
  position: relative;
  top: calc(var(--circle-size1));
  width: 100%;
  right: 40%;
  height: 10px;
  order: -1;
}

.lesson-stepper-item:last-child:after {
  content: "";
  position: relative;
  height: 10px;
  width: 0;
  order: -1;
}
.lesson-stepper-item:before {
  font-weight: 700;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.lesson-stepper-item.completed:before {
  content: url("../images/learning/completed.svg");
  padding-top: 0.2em;
  background-color: #a9f1d7;
}

.lesson-stepper-item.active:before {
  content: attr(data-before);
  color: #e4e6ea;
  background-color: #712fed;
  border: 4px solid #e3d5fb;
}

.lesson-stepper-item.inactive:before {
  content: attr(data-before);
  color: #e4e6ea;
  border: 3px solid #e4e6ea;
  background-color: #fff;
}

.tag {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 50px !important;
}

.video-complete {
  width: 50px;
  height: 50px;
  border: 6px solid #28dc9c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.dashboard-bg {
  background: url("/src/assets/images/dashboard/Group187.svg"),
    url("/src/assets/images/dashboard/group12.svg"), #240a63;
  background-repeat: no-repeat;
  background-position: -40px, right;
  min-height: 464px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-right: 29px;
}

.dashboard-teacher-bg {
  background: url("/src/assets/images/dashboard/Group187-teacher.svg"),
    url("/src/assets/images/dashboard/group12-teacher.svg"), #240a63;
  background-repeat: no-repeat;
  background-position: -40px, right;
  min-height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 29px;
}

.ball-bg {
  background: url("/src/assets/images/dashboard/ball.svg"), #240a63;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.basketball-bg {
  background: url("/src/assets/images/dashboard/basketball.svg"), #9c6df2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.shuttle-bg {
  background: url("/src/assets/images/dashboard/shuttle.svg"), #712fed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.jump-bg {
  background: url("/src/assets/images/dashboard/Jump.svg"), #00daf7;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
}
.vJump-bg {
  background: url("/src/assets/images/dashboard/vJump.svg"), #28dc9c;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
}

.speed-bg {
  background: url("/src/assets/images/dashboard/speed.svg"), #edd306;
  background-repeat: no-repeat;
  background-size: cover;
}

.setting-bg {
  background: url("/src/assets/images/dashboard/settings.svg"),
    url("/src/assets/images/dashboard/settings2.svg");
  background-repeat: no-repeat;
  background-position: center top, left 120%;
}

.custom-checkbox:checked + label {
  justify-content: flex-start;
}

.custom-checkbox:checked + label span,
.custom-checkbox2:checked + label span {
  background-color: white;
}

.custom-checkbox2:checked + label {
  justify-content: flex-end;
}

.c-stepper {
  display: flex;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}

.c-stepper__item:not(:last-child):after {
  content: "";
  position: relative;
  top: calc(var(--circle-size));
  width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
  right: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
  height: 2px;
  border-radius: 15px;
  background-color: #a689d1;
  order: -1;
}

.icon-current:before {
  content: url("../images/current.svg");
  margin-top: 20px;
}

.icon-next:before {
  content: url("../images/next.svg");
  margin-top: 20px;
}

.icon-prev:before {
  content: url("../images/prev.svg");
  margin-top: 20px;
}

.learning-bg {
  background-image: url("../images/learning/learning-bg.svg");
  background-position: bottom left;
  background-repeat: no-repeat;
}

.search-input-container {
  color: #555;
  display: flex;
  padding: 2px;
  border-radius: 6px;
  margin: 0 0 30px;
  border: none;
  padding: 0;
}

.search-input {
  border: unset;
  outline: none;
  margin: 0;
  font-size: 14px;
  color: inherit;
  border-radius: inherit;
  width: 100%;
  padding: 15px 8% 15px 40px;
  background: url("../images/search_icon.svg") no-repeat 95% center;
}

.search-input::placeholder {
  color: #d1d1d1;
}

.search-input:focus {
  box-shadow: unset;
  border-color: #712fed;
  border-width: 2px;
  outline: none;
}

.custom-scroll::-webkit-scrollbar {
  width: 0.4em;
}

.custom-scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #d4f8eb;
  margin: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #28dc9c;
  outline: 1px solid #28dc9c;
  border-radius: 30px;
}

.hidden-scroll::-webkit-scrollbar {
  width: 0.4em;
}

.hidden-scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
  margin: 10px;
}

.hidden-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid transparent;
  border-radius: 30px;
}

.certificate-bg {
  background: linear-gradient(103.79deg, #712fed -10.54%, #28dc9c 119.94%);
  box-shadow: 0px 4px 16px rgba(36, 10, 99, 0.16);
  padding: 3px;
}

.students-stepper {
  display: flex;
  width: 100%;
}

.students-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}

.students-stepper__item:not(:last-child):after {
  content: "";
  position: relative;
  top: 45%;
  width: 99%;
  right: 52%;
  height: 2px;
  border-radius: 15px;
  background-color: #d1d1d1;
  order: -1;
}

.students-stepper__item.prev:not(:last-child):after {
  background-color: #712fed;
}

.students-stepper__item.icon-current:before {
  content: url("../images/dashboard/stepper/current.svg");
  margin-top: 20px;
  z-index: 99;
}

.students-stepper__item.icon-next:before {
  content: url("../images/dashboard/stepper/next.svg");
  margin-top: 20px;
  z-index: 50;
}

.students-stepper__item.icon-prev:before {
  content: url("../images/dashboard/stepper/prev.svg");
  margin-top: 20px;
  z-index: 50;
}

.slider {
  display: flex;
  align-items: end;
  gap: 28px;
  overflow: hidden;
}

.slider-child {
  background-color: #aa82f4;
  height: 32px;
  width: 7px;
  border-radius: 10px;
  cursor: pointer;
}

.slider-child.five {
  height: 48px;
}

.slider-child.ten {
  height: 64px;
}

.slider-child.inactive {
  background-color: #dfdfdf;
}
.slider-child.acitve {
  background-color: #aa82f4;
}
.slider-child.current-value {
  background-color: #712fed;
}

.custom-opt {
  margin: 20px 10px;
  background-color: #f1eafd;
  color: #712fed;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
}

.number-arrow::-webkit-outer-spin-button,
.number-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-arrow {
  -moz-appearance: textfield;
}

.profile-bg {
  background-image: url("../images/dashboard/profile-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.profile-supervisor-bg {
  background: url("/src/assets/images/dashboard/bg-supervisor.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
}

.reports-bg {
  background-image: url("../images/dashboard/reports-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 107%;
}

.reports-ball-bg {
  background: url("/src/assets/images/dashboard/report/ball.svg"), #240a63;
  background-repeat: no-repeat;
  background-size: contain;
}
.reports-basket-bg {
  background: url("/src/assets/images/dashboard/report/basket.svg"), #9c6df2;
  background-repeat: no-repeat;
  /* background-size: contain; */
}

.reports-jump-bg {
  background: url("/src/assets/images/dashboard/report/jump.svg"), #00daf7;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
}
.reports-vjump-bg {
  background: url("/src/assets/images/dashboard/report/jumpv.svg"), #28dc9c;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
}

.reports-speed-bg {
  background: url("/src/assets/images/dashboard/report/speed.svg"), #edd306;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: top right;
}

.reports-shuttle-bg {
  background: url("/src/assets/images/dashboard/report/shuttle.svg"), #712fed;
  background-repeat: no-repeat;
}

.highest-bg {
  background: url("/src/assets/images/dashboard/report/Vector.svg"), #240a63;
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-position: -15% 100%;
}

.paresed-link a {
  color: #712fed;
  text-decoration: underline;
}

.paresed-link .sender {
  color: #712fed;
  font-weight: bold;
}

.empty-bg {
  background: url("/src/assets/images/dashboard/emptybg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.animationClass-base {
  transform: translate(70px, 265px);
}
.animationClass-1 {
  transform: translate(70px, 30px);
}
.animationClass-2 {
  transform: translate(70px, 110px);
}
.animationClass-3 {
  transform: translate(70px, 190px);
}

.animationClass-base-v {
  transform: translate(10px, 90px);
}
.animationClass-v-1 {
  transform: translate(445px, 90px);
}
.animationClass-v-2 {
  transform: translate(275px, 90px);
}
.animationClass-v-3 {
  transform: translate(110px, 90px);
}

.animationball-base {
  transform: translate(0px, 10px);
}
.animationball-3 {
  transform: translate(105px, -130px);
}
.animationball-1 {
  animation-name: ball-an1;
  animation-fill-mode: forwards;
  animation-duration: 1500ms;
  animation-timing-function: linear;
}
.animationball-2 {
  animation-name: ball-an2;
  animation-fill-mode: forwards;
  animation-duration: 1000ms;
  animation-timing-function: linear;
}

@keyframes ball-an1 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(105px, -130px);
  }
  80% {
    transform: translate(290px, -180px);
  }
  100% {
    transform: translate(475px, -130px);
  }
}
@keyframes ball-an2 {
  0% {
    transform: translate(0px, 0px);
  }
  80% {
    transform: translate(105px, -130px);
  }
  100% {
    transform: translate(290px, -180px);
  }
}

.animationRounds-1 {
  transform: translateX(140px);
}
.animationRounds-2 {
  transform: translateX(200px);
}
.animationRounds-3 {
  transform: translateX(280px);
}

.animationRuns-1 {
  transform: translateX(40px);
}
.animationRuns-2 {
  transform: translateX(180px);
}
.animationRuns-3 {
  transform: translateX(310px);
}

.customee {
  border-top-right-radius: 300px;
  border-top-left-radius: 300px;
}

.avg-bg {
  background: url("/src/assets/images/dashboard/report/avgbg.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.teeest {
  border: 10px solid #712fed;
  border-image: url("/src/assets/images/dashboard/measurement/teeest.svg");
  border-image-slice: 10 10 20 10;
}

.reports-cards-bg {
  background-image: url("/src/assets/images/dashboard/report/border.svg");
  background-size: contain;
  /* padding: 90px; */
  background-repeat: no-repeat;
}

.arrow-design {
  border-radius: 26% 7% 85% 19% / 42% 66% 79% 14%;
}

.css-1n6sfyn-MenuList {
  margin-left: 10px;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
.css-1n6sfyn-MenuList::-webkit-scrollbar {
  width: 0.3em;
}

.css-1n6sfyn-MenuList::-webkit-scrollbar-track {
  background-color: #d4f8eb;
  margin: 10px;
}

.css-1n6sfyn-MenuList::-webkit-scrollbar-thumb {
  background-color: #28dc9c;
  outline: 1px solid #28dc9c;
  border-radius: 30px;
}

.img-wrap {
  position: relative;
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 50%;
  background-color: white;
}

.profile-img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.oval-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 240px;
  height: 240px;
  border-radius: 240px 120px;
  animation: rotate 2900ms linear infinite;
}

.oval-shape-small {
  position: absolute;
  top: 0;
  left: 0;
  animation: rotate 2900ms linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.box1 {
  animation-name: animate1;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

@keyframes animate1 {
  0% {
    background-color: #240a63;
    width: 240px;
    height: 240px;
  }
  30% {
    background-color: #240a63;
  }
  50% {
    background-color: #240a63;
  }
  70% {
    background-color: #240a63;
  }
  80% {
    background-color: #240a63;
  }
  90% {
    background-color: #240a63;
  }
  100% {
    background-color: unset;
    width: 130vw;
    height: 130vw;
  }
}
